/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from '../../../../framework/date-range/dateRange';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router';
import { Grid, SvgIcon, Tab, Tabs, Box, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import FormRenderer from '../../../../framework/Inputs/formRenderer/formRenderer';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useDispatch } from 'react-redux';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import './accountsForDashboard.scss';
import { deliveryStatusForDashboard, MISC_VALUES } from '../../../../utils/constants/constants';
import CustomAccordion from '../../../../framework/accordion/customAccordion';
import Doughnut from '../../../../framework/charts/doughnut';
import { ReactComponent as ArrowDetails } from '../../../../assets/images/arrow_details.svg';
import PdfIcon from '../../../../assets/images/pdfIcon.svg';
import { abortOnGoingCallsFor, axiosConfig } from '../../../../api/axios';
import Typography from '@material-ui/core/Typography';
import {
  getDefaultDashboardValues,
  getDomesticAccounts,
  getBackGroundColor,
  setDropdownValues,
  getDriverCollectionAccounts,
  getInternationalRoadAccounts,
} from '../../../../utils/helperFunctions';
import { getInternationalAccounts } from '../../../../utils/helperFunctions';
import { formatDate } from '../../../../utils/helperFunctions';
import { firstBusinessDayOfMonth } from '../../../../utils/helperFunctions';
import { firstDayOfPreviousMonth } from '../../../../utils/helperFunctions';
import { lastDayOfPreviousMonth } from '../../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import { yesterdayDate1 } from '../../../../utils/helperFunctions';
import { weekDay } from '../../../../utils/helperFunctions';
import FindShipment from '../findShipment/findShipment';
import BreakDownDashboard from '../breakDownDashboard/BreakDownDashboard';
import * as actions from '../../../../store/actions/index';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import { Link as RouterLink } from 'react-router-dom';
import CustomDialog from '../../../../framework/dialog/customDialog';
import { useDebouncedCallback } from 'use-debounce/lib';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Pdf from 'react-to-pdf';
import { arrayValuesToQueryStringParam } from '../../../../utils/common';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const AccountsForDashboard = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    formField: { accounts, deliveryStatusForAccounts, internationalCountry },
    setFieldValue,
    formikValues,
    setValues,
    userAccounts,
  } = props;
  const moment = extendMoment(originalMoment);
  const todayDate = moment();
  var last6months = moment().subtract(6, 'months');
  var last12months = moment().subtract(12, 'months');
  var lastDay = moment().subtract(1, 'days');
  const [tabIndex, setTabIndex] = useState(0);
  const domesticUserAccounts = getDomesticAccounts(userAccounts);
  const internatonalUserAccounts = getInternationalAccounts(userAccounts);
  const internationalRoadAccounts = getInternationalRoadAccounts(userAccounts);
  const domesticDriverCollectionAccounts = getDriverCollectionAccounts(userAccounts);
  const [countryAccount, setcountryAccount] = useState('domestic');
  const [doughnutChartData, setdoughnutChartData] = useState({});
  const [totalCount, settotalCount] = useState();
  const [publicHolidays, setPublicHolidays] = useState([]);
  const [userAccountsData, setUserAccountsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [datepickerValue, setDatepickerValue] = useState(moment.range(new Date(), new Date()));

  const [productData, setProductData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [renderVal, setRenderVal] = useState('');

  var fromCollectionDate;
  let collectedToDate = formatDate(new Date());
  let total = 0;
  let status = [];
  let statusCount = [];

  useEffect(() => {
    axiosConfig
      .get(`${ServiceEndPoints.getPublicHolidays}?countryCode=gb`)
      .then((res) => {
        setPublicHolidays(res.data.publicHolidays);
      })
      .catch((err) => {
        console.log(err);
        setPublicHolidays([]);
      });
  }, []);

  useEffect(() => {
    if (tabIndex === 0) {
      fromCollectionDate = formatDate(yesterdayDate1(publicHolidays));
      collectedToDate = formatDate(yesterdayDate1(publicHolidays));
    } else {
      fromCollectionDate = formatDate(new Date());
      collectedToDate = formatDate(new Date());
    }
    if (formikValues.deliveryStatusForAccounts.value === MISC_VALUES.THIS_MONTH) {
      fromCollectionDate = formatDate(
        firstBusinessDayOfMonth(new Date().getFullYear(), new Date().getMonth()),
      );
      collectedToDate = formatDate(new Date());
    } else if (formikValues.deliveryStatusForAccounts.value === MISC_VALUES.PREVIOUS_MONTH) {
      fromCollectionDate = formatDate(
        firstDayOfPreviousMonth(new Date().getFullYear(), new Date().getMonth()),
      );
      collectedToDate = formatDate(
        lastDayOfPreviousMonth(new Date().getFullYear(), new Date().getMonth()),
      );
    } else if (formikValues.deliveryStatusForAccounts.value === MISC_VALUES.THIS_WEEK) {
      fromCollectionDate = formatDate(weekDay(new Date()));
      collectedToDate = formatDate(new Date());
    }
    const dateValues = {
      fromCollectionDate,
      toCollectionDate: collectedToDate,
    };
    setValues({ ...formikValues, ...dateValues });
  }, [formikValues.deliveryStatusForAccounts, formikValues.isDomestic, publicHolidays]);

  const handleTabIndexChange = (event, value) => {
    setTabIndex(value);
  };

  useEffect(() => {
    if (tabIndex === 0) {
      fromCollectionDate = formatDate(yesterdayDate1(publicHolidays));
      collectedToDate = formatDate(yesterdayDate1(publicHolidays));
    } else {
      fromCollectionDate = formatDate(new Date());
      collectedToDate = formatDate(new Date());
    }
    const dateValues = {
      fromCollectionDate,
      toCollectionDate: collectedToDate,
    };
    setValues({ ...formikValues, ...dateValues });
  }, [tabIndex]);

  const getDoughnutChartData = useDebouncedCallback(() => {
    abortOnGoingCallsFor(`${ServiceEndPoints.getBreakDownDetails}summary`);
    axiosConfig
      .get(
        `${ServiceEndPoints.getBreakDownDetails}summary?${getDefaultDashboardValues(
          formikValues,
        )}&${arrayValuesToQueryStringParam(formikValues.accounts)}`,
      )
      .then((res) => {
        for (const dataObj of res.data?.shipmentByStatuses) {
          total = res.data.shipmentTotalCount;
          status.push(dataObj.status);
          statusCount.push(dataObj.totalCount);
        }
        let percentage = status.map((label, index) => (statusCount[index] / total) * 100);


        let customLabels = status.map((label, index) => {
          let validPercentage = `${percentage[index] >= 1 ? `${percentage[index] >= 10 ? parseFloat(percentage[index]).toFixed(1) : '0' + parseFloat(percentage[index]).toFixed(1)}` : parseFloat(percentage[index]).toFixed(2)}`

          return {
            percentage: `${validPercentage}%`,
            label: `${label}`,
            StatusCount: `${statusCount[index]}`,
          };
        });

        let labelForView = status.map((label) => `${label}`);
        let backgroundColorForLabel = labelForView.map((label) => getBackGroundColor(label));
        setdoughnutChartData({
          labels: customLabels,
          datasets: [
            {
              backgroundColor: backgroundColorForLabel,
              hoverBackgroundColor: backgroundColorForLabel,
              data: statusCount,
              label: labelForView,
            },
          ],
        });
        settotalCount(total);
      })
      .catch((err) => {
        setdoughnutChartData({});
        settotalCount();
      });
  }, 400);

  useEffect(() => {
    if (formikValues.fromCollectionDate && formikValues.toCollectionDate && formikValues.accounts.length > 0) {
      getDoughnutChartData();
    }
  }, [
    formikValues.isDomestic,
    formikValues.fromCollectionDate,
    formikValues.toCollectionDate,
    formikValues.accounts,
    formikValues.internationalCountry,
  ]);

  const handleAccounts = (event, newAccount) => {
    if (newAccount !== null) {
      setcountryAccount(newAccount);
    }
    if (newAccount === MISC_VALUES.DOMESTIC) {
      const defaultAccountSelected = selectDefaultAccount(userAccountsData, domesticDriverCollectionAccounts);
      setFieldValue('accounts', [defaultAccountSelected]);
      setFieldValue('isDomestic', true);
    } else {
      setFieldValue('isDomestic', false);
    }
  };

  useEffect(() => {
    setFieldValue('deliveryStatusForDomesticAccounts', deliveryStatusForDashboard[0]);
  }, []);

  useEffect(() => {
    if ((domesticUserAccounts || []).length <= 0) {
      setFieldValue('isDomestic', false);

      setcountryAccount('international');
    } else if ((internatonalUserAccounts || []).length <= 0) {
      setcountryAccount('domestic');
      setFieldValue('isDomestic', true);
    }
  }, [domesticUserAccounts]);

  const dispatchingAction = () => {
    dispatch(
      actions.setDashboardTracking({
        loadedFrom: 'detailView',
        loadedResponse: formikValues,
        dashboardQueryParams: `${getDefaultDashboardValues(formikValues)}`,
        accountsParams: arrayValuesToQueryStringParam(formikValues.accounts),
        tabIndex,
        products: productData,
        countries: props.countries,
        services: serviceData,
      }),
    );
    history.push('/dashboard/dashboardDetailsSummary');
  };

  useEffect(() => {
    if (userAccounts) {
      let _userAccounts = userAccounts;
      _userAccounts =
        _userAccounts.map((account) => {
          return {
            ...account,
            displayName: `${account.accountId}-${account.accountName}${(account.configuration.firstMileDropOffAllowed) ? '-FMD' : ''}`,
          };
        }) ?? [];
      if (formikValues.isDomestic) {
        setUserAccountsData(getDomesticAccounts(userAccounts));
        // _userAccounts = getDomesticAccounts(userAccounts)
      } else {
        setUserAccountsData(getInternationalAccounts(userAccounts));
        // _userAccounts = getInternationalAccounts(userAccounts)
        setFieldValue('internationalCountry', setDropdownValues(props.countries, 'code2Digit', ''));
      }
    }
    setFieldValue('deliveryStatusForAccounts', deliveryStatusForDashboard[0]);
  }, [formikValues.isDomestic, userAccounts]);

  useEffect(() => {
    if (formikValues.isDomestic) {
      const defaultAccountSelected = selectDefaultAccount(userAccountsData, domesticDriverCollectionAccounts);
      defaultAccountSelected && setFieldValue('accounts', [defaultAccountSelected]);
    } else {
      const defaultAccountSelected = selectDefaultAccount(userAccountsData, internationalRoadAccounts);
      defaultAccountSelected && setFieldValue('accounts', [defaultAccountSelected]);
    }
  }, [userAccountsData]);

  const selectDefaultAccount = (userAccountsData, selectedAccounts) => {
    let defaultAccountSelected = '';
    let isValueExist = false;
    for (let i = 0; i < selectedAccounts.length; i++) {
      for (let j = 0; j < userAccountsData.length; j++) {
        if (userAccountsData[j].accountId == selectedAccounts[i].accountId) {
          defaultAccountSelected = userAccountsData[j].accountId;
          isValueExist = true;
        }
      }
      if (isValueExist) break;
    }
    return defaultAccountSelected;
  };

  useEffect(() => {
    // dateRange(formikValues.deliveryStatusForAccounts.value);
  }, [formikValues.deliveryStatusForAccounts]);

  const dateRange = (selectedValue) => {
    if (props.dashboardDetails.loadedFrom === MISC_VALUES.DASHBOARD_DETAILS && props?.dashboardDetails?.loadedResponse) {
      setOpen(false)
      setFieldValue('deliveryStatusForAccounts', deliveryStatusForDashboard[0]);
    }
    else if (selectedValue === MISC_VALUES.DATE_RANGE) {
      setOpen(true);
    }
  };

  const dateRangeInSelect = () => {
    setOpen(true);
  };
  const handleDateClose = (value) => {
    setOpen(false);
    fromCollectionDate = formatDate(datepickerValue.start._i);
    collectedToDate = formatDate(datepickerValue.end._d);

    const dateValues = {
      fromCollectionDate,
      toCollectionDate: collectedToDate,
    };
    setRenderVal(`${moment(fromCollectionDate).format('DD/MM/YYYY')}-${moment(collectedToDate).format('DD/MM/YYYY')}`);
    setValues({ ...formikValues, ...dateValues });
  };

  const onSelect = (value) => {
    const count = getNumberOfDays(value.start, value.end);
    if (count > 30) {
      setDatepickerValue(moment.range(value.start._d, moment(value.end._d).subtract(count - 30, "days")));
    } else {
      setDatepickerValue(moment.range(value.start._d, value.end._d));
    }
  };

  function getNumberOfDays(start, end) {
    if (start < end) {
      return (end - start) / 1000 / 60 / 60 / 24;
    }
  }

  useEffect(() => {
    if (props.dashboardDetails.loadedFrom === MISC_VALUES.DASHBOARD_DETAILS && props?.dashboardDetails?.loadedResponse) {
      const responseFormikValues = props.dashboardDetails.loadedResponse;
      const retainedValues = {
        accounts: responseFormikValues?.accounts ?? [],
        isDomestic: responseFormikValues?.isDomestic,
        deliveryStatusForAccounts: responseFormikValues?.deliveryStatusForAccounts,
      };
      setValues({ ...formikValues, ...retainedValues });
      if (!responseFormikValues?.isDomestic) {
        setcountryAccount('international');
      }
      if (props.dashboardDetails.tabIndex) {
        setTabIndex(props.dashboardDetails.tabIndex);
      }
    }
  }, [props.dashboardDetails, publicHolidays]);

  useEffect(() => {
    setFieldValue('internationalCountry', setDropdownValues(props.countries, 'code2Digit', ''));
    const responseFormikValues = props.dashboardDetails.loadedResponse;
    if (
      props.dashboardDetails.loadedFrom === MISC_VALUES.DASHBOARD_DETAILS && props?.dashboardDetails?.loadedResponse &&
      props.countries.length > 0 &&
      responseFormikValues.internationalCountry
    ) {
      setFieldValue(
        'internationalCountry',
        setDropdownValues(props.countries, 'code2Digit', responseFormikValues.internationalCountry.code2Digit),
      );
    }
  }, [props.countries]);

  const componentRef = React.useRef();
  useEffect(() => {
    deliveryStatusForDashboard[4].clickEvent = dateRangeInSelect;
    if (formikValues.deliveryStatusForAccounts.value === MISC_VALUES.DATE_RANGE) {
      dateRange('daterange');
    } else {
      setOpen(false);
      deliveryStatusForDashboard[4].name = 'Date range';
    }
  }, [formikValues.deliveryStatusForAccounts]);
  const ref = React.createRef();


  const options = {
    orientation: 'potrait',
  };

  return (
    <Grid
      container
      id="dpie"
      options={props.options}
      className="dashboardContainer"
      direction="row"
      justify="space-evenly"
      spacing={1}
    >
      <Grid className="pb-0" item xs={12} sm={6} md={6} lg={7}>
        <Grid container spacing={3} direction="row" alignItems="flex-start" justify="space-between">
          <Grid item xs={6} sm={6} md={6} lg={6} ref={componentRef}>
            <ToggleButtonGroup value={countryAccount} exclusive onChange={handleAccounts}>
              <ToggleButton value="domestic" disabled={(domesticUserAccounts || []).length <= 0}>
                <span>Domestic</span>
              </ToggleButton>
              <ToggleButton value="international" disabled={(internatonalUserAccounts || []).length <= 0}>
                <span>International</span>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Grid container direction="row" spacing={2} justify="space-between">
              <Grid item xs={10}>
                {formikValues.isDomestic ? null : (
                  <FormRenderer {...internationalCountry.props} data={props.countries} fullWidth />
                )}
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Pdf render targetRef={props.targetRef} filename="Dashboard_Graph.pdf" scale={0.9} options={options}>
                  {({ toPdf }) => (
                    <img
                      src={PdfIcon}
                      style={{ height: 30, marginRight: -6, cursor: 'pointer' }}
                      alt=""
                      title={'Export to PDF'}
                      // onClick={toPdf}
                      onClick={props.printDocument}
                    ></img>
                  )}
                </Pdf>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} direction="row" alignItems="flex-start" className="SelectContainer">
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormRenderer {...accounts.props} data={userAccountsData} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <FormRenderer
              {...deliveryStatusForAccounts.props}
              data={deliveryStatusForDashboard}
              fullWidth
              renderVal={renderVal}
            />
          </Grid>

          <CustomDialog open={open} onClose={handleDateClose} title="Date range" className="datePicker-dialog-box">
            <DateRangePicker
              onSelect={onSelect}
              value={datepickerValue}
              datepickerValue={datepickerValue}
              open={true}
              singleDateRange={true}
              minimumDate={formikValues.isDomestic ? last6months : last12months}
              maximumDate={lastDay}
            />
            <Grid className="datepicker-btn-grid">
              <InputButton
                variant="contained"
                label="OK"
                className="contained-btn datepicker-btn"
                onClick={handleDateClose}
              />
            </Grid>
          </CustomDialog>
        </Grid>
        <Grid container alignItems="flex-start" style={{ paddingTop: '1.5%' }}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <span style={{ fontSize: '12px', color: '#707070' }}>Selected accounts:</span> &nbsp;
            <span style={{ fontSize: '16px' }}>{((formikValues || {}).accounts || []).join(', ')}</span>
          </Grid>
        </Grid>
        {formikValues.deliveryStatusForAccounts?.value === 'today&yesterday' ? (
          <Grid container alignItems="flex-start" className="accountsTabContaner">
            <Grid className="chartSection" item xs={12} sm={12} md={12} lg={12}>
              <CustomAccordion heading="Delivery status summary" className="accountsForDashboard-accordion">
                <Grid container>
                  <Tabs
                    className="accountsTabContaner"
                    value={tabIndex}
                    onChange={handleTabIndexChange}
                    aria-label="simple tabs example"
                  >
                    <Tab label="Collected yesterday" {...a11yProps(0)} />
                    <Tab label="Collected today" {...a11yProps(1)} />
                  </Tabs>
                  <TabPanel className="YesDayPanel" value={tabIndex} index={0}>
                    {(formikValues || {}).accounts.length > 0 ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} className="doughnut-with-panel">
                        <Doughnut
                          data={doughnutChartData}
                          text={totalCount}
                          formikValues={formikValues}
                          tabIndex={tabIndex}
                          products={productData}
                          countries={props.countries}
                          services={serviceData}
                        />
                      </Grid>
                    ) : null}
                    <Grid container alignItems="flex-start" direction="row" justify="flex-end" className="detailsview">
                      <Grid item>
                        <InputButton
                          label="Detail view"
                          style={{ textTransform: 'none', fontSize: '16px' }}
                          endIcon={<SvgIcon fontSize="large" component={ArrowDetails} />}
                          component={RouterLink}
                          onClick={dispatchingAction}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>

                  <TabPanel className="toDayPanel" value={tabIndex} index={1}>
                    {(formikValues || {}).accounts.length > 0 ? (
                      <Grid item xs={12} sm={12} md={12} lg={12} className="doughnut-with-panel">
                        <Doughnut data={doughnutChartData} text={totalCount} formikValues={formikValues} />
                      </Grid>
                    ) : null}
                    <Grid container alignItems="flex-start" direction="row" justify="flex-end" className="detailsview">
                      <Grid item>
                        <InputButton
                          label="Detail view"
                          style={{ textTransform: 'none', fontSize: '16px' }}
                          endIcon={<SvgIcon fontSize="large" component={ArrowDetails} />}
                          component={RouterLink}
                          onClick={dispatchingAction}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Grid>
              </CustomAccordion>
            </Grid>
          </Grid>
        ) : (
          <Grid container alignItems="flex-start" className="accountsTabContaner">
            <Grid className="chartSection" item xs={12} sm={12} md={12} lg={12}>
              <CustomAccordion heading="Summary" className="accoutsForDashboard-accordion">
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} className="doughnut-without-panel">
                    {(formikValues || {}).accounts.length > 0 ? (
                      <Doughnut
                        data={doughnutChartData}
                        text={totalCount}
                        formikValues={formikValues}
                        tabIndex={tabIndex}
                        products={productData}
                        countries={props.countries}
                        services={serviceData}
                      />
                    ) : null}
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="flex-start"
                  direction="row"
                  justify="flex-end"
                  className="detailsview-Without-Panel"
                >
                  <Grid item style={{ marginRight: '75%' }}>
                    <InputButton
                      label="Detail view"
                      style={{ textTransform: 'none', fontSize: '16px', width: 'max-content' }}
                      endIcon={<SvgIcon fontSize="large" component={ArrowDetails} />}
                      component={RouterLink}
                      onClick={dispatchingAction}
                    />
                  </Grid>
                </Grid>
              </CustomAccordion>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid className="BreakdownContaner pb-0" item xs={6} sm={6} md={6} lg={5}>
        <FindShipment
          {...props}
          tabIndex={tabIndex}
          products={productData}
          countries={props.countries}
          services={serviceData}
        />
        <BreakDownDashboard {...props} setProductData={setProductData} setServiceData={setServiceData} />
      </Grid>
    </Grid>
  );
};
AccountsForDashboard.propTypes = {
  formField: PropTypes.object,
  isDomestic: PropTypes.bool,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    dashboardDetails: ((state || {}).dashboardTracking || {}).dashboardTracking,
    userAccounts: (((state || {}).auth || {}).userDetails || {}).accounts,
  };
};
export default connect(mapStateToProps, null)(AccountsForDashboard);
