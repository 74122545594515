import React, { useState, useEffect } from 'react'
 
import CustomCaption from './CustomCaption';
 
import { Grid, Typography } from '@material-ui/core';
 
const DeliveryOptionsSummary = ({ deliveryOptions, servicePoint ,deliveryChoice}) => {
  const [doorStepInstructionsValue, setDoorStepInstructionsValue] = useState('')
  useEffect(() => {
    if (deliveryOptions.addressType && deliveryOptions.addressType !== null && deliveryOptions.addressType.toLowerCase() === 'doorstep') {
      if (deliveryChoice === "NYN/PIN") {
        setDoorStepInstructionsValue('Deliver to doorstep with PIN');
      } 
      else if (deliveryChoice === "NYN/AGE") {
        setDoorStepInstructionsValue('Deliver to doorstep with AGE verification');
      } else {
        if (deliveryOptions.neighbourDelivery) {
          setDoorStepInstructionsValue('Deliver to neighbour if no answer at doorstep');
        } 
        else if (deliveryOptions.secureLocationDelivery) {
          setDoorStepInstructionsValue('Leave safe if no answer at doorstep');
        } 
        else {
          setDoorStepInstructionsValue('Deliver to doorstep only');
        }
      }
    }

  }, [deliveryOptions, deliveryChoice])
  return (
    <Grid>
      <Grid container xs={12} sm={12} lg={12} spacing={3}>
        <Grid item xs={12} lg={6}>
          {(deliveryOptions || {}).addressType !== ''
            ? `Delivery type:  ${((deliveryOptions || {}).addressType !== null && (deliveryOptions || {}).addressType !== undefined) ? ((deliveryOptions || {}).addressType.toLowerCase() === 'servicepoint' ? 'Deliver to ServicePoint' : 'Deliver to doorstep') : ''}`
            : null}
        </Grid>
 
      </Grid>
      <Grid container xs={12} sm={12} lg={12} spacing={3}>
        {(deliveryOptions?.addressType)?.toLowerCase() === 'servicepoint'
          ?
          (<Grid item xs={12} lg={3}>
            <CustomCaption title="Delivery ServicePoint" value={(servicePoint !== null || servicePoint !== undefined) ? servicePoint : '-'} />
          </Grid>
          )
          :
          (<Grid item xs={12} lg={3}>
            <CustomCaption title="Doorstep instructions" value={doorStepInstructionsValue} />
          </Grid>
          )
        }
        {(deliveryOptions?.leaveSafeLocation !== '') ?
          <Grid item xs={12} lg={3}>
            <CustomCaption title="Special instructions" value={(deliveryOptions || {}).specialInstructions !== null ? (deliveryOptions || {}).specialInstructions + ' ' + (deliveryOptions || {}).leaveSafeLocation : (deliveryOptions || {}).leaveSafeLocation} />
          </Grid>
          :
          <Grid item xs={12} lg={3}>
            <CustomCaption title="Special instructions" value={(deliveryOptions || {}).specialInstructions !== null ? (deliveryOptions || {}).specialInstructions : '-'} />
          </Grid>
        }
      </Grid>
      <Grid container xs={12} sm={12} lg={12} spacing={3}>
        <Grid item xs={12} lg={6}>
          <Typography variant="body2" >
            {(deliveryOptions || {}).exchangeOnDelivery !== ''
              ? `Exchange on delivery: ${((deliveryOptions || {}).exchangeOnDelivery !== null && (deliveryOptions || {}).exchangeOnDelivery !== undefined) ? ((deliveryOptions || {}).exchangeOnDelivery ? 'Yes' : 'No') : ''}`
              : null}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
 
export default DeliveryOptionsSummary