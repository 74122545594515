import * as CONSTANTS from '../utils/constants/constants';
import { axiosConfig } from '../api/axios';
import { ServiceEndPoints } from './constants/ApiConstant';

/**
 * Method to get the maximum weight to amend Service point
 *
 * @param {*} data - array of object from "productCountryFeature" API
 * @returns - number - Maximum unique data from filtered array of object
 */
export const getServicepointWeight = (data) => {
  const filteredMaxWeight = data
    .filter(
      (ele) =>
        ele.addressType === CONSTANTS.AMEND_SERVICE_POINT.ADDRESS_TYPE &&
        (ele.productCode === CONSTANTS.AMEND_SERVICE_POINT.PRO_CODE_210 ||
          ele.productCode === CONSTANTS.AMEND_SERVICE_POINT.PRO_CODE_220 ||
          ele.productCode === CONSTANTS.AMEND_SERVICE_POINT.PRO_CODE_1),
    )
    .map((x) => x.maximumWeight);

  const uniqueMaxWeight = [...new Set(filteredMaxWeight)][0];
  return uniqueMaxWeight;
};

export const getServicePointDropOffDetails = () => {
  return axiosConfig
    .get(`${ServiceEndPoints.getMappedProducts}?shipmentType=parceluk`)
    .then((response) => {
      response = response.data;
      // Maximum Service Point Collection Weight and Parcels
      let allServicePointMaxWeightList = response.map((x) => x.servicePointCollectionMaximumWeight);
      let allServicePointMaxWeightPerParcelsList = response.map((x) => x.servicePointCollectionMaximumWeightPerParcel);

      // Maximum Service Point Collection Length, Width and Height
      let allServicePointMaxLengthList = response.map((x) => x.servicePointCollectionMaximumLength);
      let allServicePointMaxWidthList = response.map((x) => x.servicePointCollectionMaximumWidth);
      let allServicePointMaxHeightList = response.map((x) => x.servicePointCollectionMaximumHeight);

      // Minimum Service Point Collection Weight
      let allServicePointMinWeightList = response.map((x) => x.servicePointCollectionMinimumWeight);

      // Minimum Service Point Collection Length, Width and Height
      let allServicePointMinLengthList = response.map((x) => x.servicePointCollectionMinimumLength);
      let allServicePointMinWidthList = response.map((x) => x.servicePointCollectionMinimumWidth);
      let allServicePointMinHeightList = response.map((x) => x.servicePointCollectionMinimumHeight);

      const servicePointCollection = {
        maxWeight: Math.max(...allServicePointMaxWeightList),
        maxItemWeight: Math.max(...allServicePointMaxWeightPerParcelsList),
        maxLength: Math.max(...allServicePointMaxLengthList),
        maxWidth: Math.max(...allServicePointMaxWidthList),
        maxHeight: Math.max(...allServicePointMaxHeightList),
        minWeight: Math.min(...allServicePointMinWeightList),
        minLength: Math.min(...allServicePointMinLengthList),
        minWidth: Math.min(...allServicePointMinWidthList),
        minHeight: Math.min(...allServicePointMinHeightList),
      };

      return servicePointCollection;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getServicePointWeight = (formikValues, servicePointCollectionData, servicePointWeight) => {
  return formikValues.handOverMethod !== CONSTANTS.AMEND_SERVICE_POINT.HANDOVER_DRIVER_COLLECTION
    ? servicePointCollectionData
      ? servicePointCollectionData.maxWeight
      : 0
    : servicePointWeight
    ? servicePointWeight
    : 0;
};
