export const ERROR_MSG = {
  FOR_60_CHAR: 'Max 60 characters are allowed',
  FOR_30_CHAR: 'Max 30 characters are allowed',
  LENGTH_VALIDATION_MIN_MSG: 'Declared length must be greater than or equal to',
  LENGTH_VALIDATION_MAX_MSG: 'Declared length must be less than or equal to',
  WIDTH_VALIDATION_MIN_MSG: 'Declared width must be greater than or equal to',
  WIDTH_VALIDATION_MAX_MSG: 'Declared width must be less than or equal to',
  HEIGHT_VALIDATION_MIN_MSG: 'Declared height must be greater than or equal to',
  HEIGHT_VALIDATION_MAX_MSG: 'Declared height must be less than or equal to',
  WEIGHT_OUTSIDE_PERMITTED_RANGE: 'Weight is outside permitted range',
  DECLARED_WEIGHT_LESS_OR_EQUAL: 'Declared weight must be less than or equal to',
  DECLARED_WEIGHT_GREATER_THAN: 'Declared weight must be greater than '
};
