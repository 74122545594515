import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { getYupSchemaFromMetaData } from '../../../../utils/yupSchema/yupSchemaGenerator';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import DeliveryOptions from '../../addShipment/deliveryOptions/deliveryOptions';
import ProductAndServiceDetails from '../../addShipment/productAndServiceDetails/productAndServiceDetails';
import InputButton from '../../../../framework/Inputs/inputButton/inputButton';
import templateManagementForm from '../../../../utils/formConfig/templateManagementForm/templateManagementForm';
import CustomAccordion from '../../../../framework/accordion/customAccordion';
import AdditionalInfo from '../../addShipment/additionalInfo/additionalInfo';
import TemplateName from '../../../../components/addShipment/TemplateManagement/TemplateName';
import ParcelDetails from '../../addShipment/parcelDetails/parcelDetails';
import {
  formatTemplateModelForSave,
  setValuesToForm,
  dynamicSort,
  getCustomerAccountID,
  setDropdownValues,
} from '../../../../utils/helperFunctions';
import { ServiceEndPoints } from '../../../../utils/constants/ApiConstant';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { abortOnGoingCallsFor, axiosConfig, GET } from '../../../../api/axios';
import './templateManagement.scss';
import * as actions from '../../../../store/actions/index';
import CustomModal from '../../../../framework/modal/modal';
import ConfirmationDialog from '../../../../components/addressBook/confirmation/confirmation';
import CustomSnackBar from '../../../../framework/snackBar/customSnackBar';
import { doorStepInstructionOptions } from '../../../../utils/constants/constants';

const { formId, formField } = templateManagementForm;

const TemplateManagement = (props) => {
  const history = useHistory();
  const { onLoad } = props;

  const formikRef = useRef();
  const dispatch = useDispatch();
  const [templateNameExist, setTemplateNameExist] = React.useState(false);
  const [templateDetails, setTemplateDetails] = useState({});
  const [countries, setCountries] = useState([]);
  const [templateNameEdited, setTemplateNameEdited] = React.useState(false);
  const [confirmationMsg, setConfirmationMsg] = useState('');
  const [snackBarMsg, setSnackBarMsg] = React.useState('');
  const [severity, setSeverity] = React.useState('success');

  const [showCancelConfirmationDialog, setShowCancelConfirmationDialog] = useState(false);

  const [templateManagementSchema, setTemplateManagementSchema] = useState(getYupSchemaFromMetaData(formField, [], []));

  const initialValues = {};

  const counrtyValue = {
    code: 'GBR',
    code2Digit: 'GB',
    code3Digit: '826',
    iossApplies: false,
    isEU: false,
    name: 'United Kingdom',
    postcodeMask: 'AN NAA or ANN NAA or AAN NAA or AANN NAA or ANA NAA or AANA NAA (spaces optional)',
    postcodeOptional: false,
    postcodeRegex:
      '([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\\s?[0-9][A-Za-z]{2})',
    paperlessTradeCapabilities: [
      { DHLDivision: 'express', currencyCode: 'USD', maximumDeclaredValue: 99999.99, inbound: true },
    ],
  };

  for (let property in formField) {
    if (Object.prototype.hasOwnProperty.call(formField, property)) {
      const field = formField[property];
      if (field.props.type === 'checkbox' || field.props.type === 'radio') {
        initialValues[field.props.name] = field.value;
      } else if (field.props.type === 'array') {
        initialValues[field.props.name] = field.values;
      } else {
        initialValues[field.props.name] = field.value || '';
      }
    }
  }
  initialValues["destinationCountry"] = counrtyValue;

  useEffect(() => {
    if (history.location.search) {
      populateTemplate(history.location.search);
      getCountriesList();
    }
  }, []);

  const populateTemplate = (searchParams) => {
    searchParams = searchParams.replace('?', '');
    return axiosConfig
      .get(`${ServiceEndPoints.getTemplateBy}?organization=${getCustomerAccountID()}&isExactMatch=true&${searchParams}`)
      .then((res) => {
        setTemplateNameEdited(true);
        setTemplateDetails(res.data?.templates?.[0]);
        dispatch(
          actions.setShipmentId({
            shipmentId: res.data?.templates?.[0].templateName,
          }),
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Gets the list of All countries
  const getCountriesList = () => {
    GET(ServiceEndPoints.getCountries)
      .then((response) => {
        setCountries(response.data.countries.sort(dynamicSort('name')));
      })
      .catch((error) => {
        setCountries([]);
      });
  };

  useEffect(() => {
    if (Object.keys(templateDetails).length > 0 && countries.length > 0) {
      templateDetails['destinationCountry'] = 'GBR';
      formikRef.current.setValues(setValuesToForm(templateDetails, formikRef.current.values, countries));
      onLoad({
        loadedFrom: 'dropdownResponse',
        loadedResponse: { ...templateDetails },
      });
      formikRef.current.setFieldValue('weight', templateDetails?.parcelDetails?.weight);
      setTimeout(() => {
        onLoad({ loadedFrom: '' });
      }, 10000);
      formikRef.current.setFieldValue(
        'doorStepInstructions',
        setDropdownValues(doorStepInstructionOptions, 'value', templateDetails?.deliveryOptions?.doorStepInstructions),
      );
    }
  }, [templateDetails, countries]);

  const getTemplate = (templateName) => {
    abortOnGoingCallsFor(ServiceEndPoints.getTemplateBy);
    return axiosConfig
      .get(
        `${ServiceEndPoints.getTemplateBy
        }?organization=${getCustomerAccountID()}&isExactMatch=true&templateName=${templateName.trim().toLowerCase()}`,
      )
      .then((res) => {
        setTemplateNameExist(true);
        return true;
      })
      .catch((err) => {
        setTemplateNameExist(false);
        return false;
      });
  };

  const isFormValid = async (errors, values) => {
    if (errors.weight || errors.templateName || errors.noOfItems || errors.extendedCoverDomestic || (values?.createPalletShipment==='yes' && errors.pallets)) {
      return false;
    }

    if (!templateNameEdited && values.templateName.trim()) {
      return !(await getTemplate(values.templateName));
    } else {
      return true;
    }
  };

  const handleSubmit = async (values, errors, resetForm) => {
    if (await isFormValid(errors, values)) {
      dispatch(actions.setLoaderLayOver(true));
      const reqObj = formatTemplateModelForSave(values, true);
      axiosConfig
        .post(ServiceEndPoints.postTemplate, reqObj)
        .then((res) => {
          dispatch(actions.setAppSnackBarData({ msg: `Template saved successfully` }));
          resetForm();
          dispatch(actions.setActiveTab('myTemplates'));
          history.push(`/shipment/templateManagement`);
        })
        .catch((err) => {
          console.log(err);
          dispatch(
            actions.setBottomAlert({
              msgs: [
                {
                  type: 'error',
                  msg: `${err?.response?.data[0]?.detail ?? 'Error occurred while saving, please try again'}`,
                },
              ],
            }),
          );
        })
        .finally(() => {
          dispatch(actions.setLoaderLayOver(false));
        });
    } else {
      dispatch(
        actions.setBottomAlert({
          msgs: [
            {
              type: 'error',
              msg: 'The form has errors, please correct and try again',
            },
          ],
        }),
      );
    }
  };

  const cancelSchema = () => {
    setConfirmationMsg('Are you sure you want to cancel, all unsaved changes will be lost?');
    setShowCancelConfirmationDialog(true);
  };
  const handleDialogClose = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onDialogCancel = () => {
    setShowCancelConfirmationDialog(false);
  };

  const onCancelDialogConfirm = () => {
    dispatch(actions.setActiveTab('myTemplates'));
    history.goBack();
    setShowCancelConfirmationDialog(false);
  };

  const updateValidationSchema = () => {
    setTemplateManagementSchema(getYupSchemaFromMetaData(formField, [], []));
  };

  useEffect(() => {
    if (Object.keys(props.selectedAccount).length > 0) {
      formikRef.current.setFieldValue('accountNumber', props.selectedAccount);
    }
  }, [props.selectedAccount]);

  return (
    <Grid className="shipment">
      <Grid>
        <React.Fragment>
          <Formik
            initialValues={initialValues}
            validationSchema={templateManagementSchema}
            enableReinitialize={true}
            innerRef={formikRef}
          >
            {({ values, setFieldValue, setFieldTouched, setValues, errors, setErrors, resetForm }) => (
              <Form id={formId} noValidate>
                <Grid container spacing={3} direction="row" justify="space-between" alignItems="flex-start">
                  <Grid item></Grid>
                </Grid>
                <CustomAccordion heading="Template details">
                  <TemplateName
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    templateNameExist={templateNameExist}
                    errors={errors}
                    getTemplate={getTemplate}
                    setValues={setValues}
                    templateNameEdited={templateNameEdited}
                  />
                </CustomAccordion>

                <CustomAccordion heading="Parcel details">
                  <ParcelDetails
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    errors={errors}
                    isDomestic={true}
                    isTemplate={true}
                    updateValidationSchema={updateValidationSchema}
                    setValues={setValues}
                    setErrors={setErrors}
                    isParcelHandover={false}
                  />
                </CustomAccordion>
                <CustomAccordion heading="Delivery options">
                  <DeliveryOptions
                    formField={formField}
                    formikValues={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    isDomestic={true}
                    isTemplate={true}
                    setValues={setValues}
                  />
                </CustomAccordion>

                <CustomAccordion heading="Product and service">
                  <ProductAndServiceDetails
                    formField={formField}
                    formikValues={values}
                    isDomestic={true}
                    setFieldValue={setFieldValue}
                    isTemplate={true}
                    setValues={setValues}
                  />
                </CustomAccordion>

                <CustomAccordion heading="Additional information about your shipment">
                  <AdditionalInfo
                    formField={formField}
                    formikValues={values}
                    isDomestic={true}
                    setFieldValue={setFieldValue}
                    setValues={setValues}
                  />
                </CustomAccordion>
                <Grid
                  className="Temp-btn-container"
                  container
                  spacing={3}
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                  style={{ paddingTop: '10px' }}
                >
                  <Grid item>
                    <InputButton
                      variant="text"
                      className="text-btn"
                      // component={NavLink}
                      // to={'/shipment/addShipment'}
                      onClick={() => cancelSchema()}
                      label="Cancel "
                    />
                    <Grid container item className="customModal-book">
                      <CustomModal
                        open={showCancelConfirmationDialog}
                        title=""
                        onClose={handleDialogClose}
                        maxWidth="sm"
                      >
                        <ConfirmationDialog
                          confirmationMessage={confirmationMsg}
                          onConfirm={onCancelDialogConfirm}
                          OnCancel={onDialogCancel}
                        />
                      </CustomModal>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <InputButton
                      variant="contained"
                      className="contained-btn"
                      onClick={() => {
                        handleSubmit(values, errors, resetForm);
                      }}
                      buttonType="submit"
                      label="Save template"
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </React.Fragment>
        <CustomSnackBar snackBarMsg={snackBarMsg} setSnackBarMsg={setSnackBarMsg} severity={severity} />
      </Grid>
    </Grid>
  );
};

TemplateManagement.propTypes = {};

const mapStateToProps = (state) => {
  return {
    selectedAccount: state.accounts.selectedAccount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoad: (loadedFrom) => {
      dispatch(actions.setLoadedFrom(loadedFrom));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateManagement);
