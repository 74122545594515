import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, IconButton } from '@material-ui/core';
import FormRenderer from '../../../framework/Inputs/formRenderer/formRenderer';
import { axiosConfig, GET } from '../../../api/axios';
import { useSelector } from 'react-redux';
import CustomDialog from '../../../framework/dialog/customDialog';
import CustomAddressTable from '../../../framework/CustomAddressTable/CustomAddressTable';
import { SearchIcon } from '@material-ui/data-grid';
import {
  formatPostalCodeValues,
  removeUndefinedProperties,
  getCustomerAccountID,
  getWorkingDays,
  setValuesToParcelDetails,
} from '../../../utils/helperFunctions';
import { MIN_SEARCH_RESULTS_REQUIRED } from '../../../utils/constants/constants';
import { renderEmptyText } from '../../../utils/common';
import { DeliveryTypeOptions, SearchByOptions } from '../../../utils/constants/carriageForwardConstants';
import { ServiceEndPoints } from '../../../utils/constants/ApiConstant';
import AddToAddressAndFav from '../../helperComponents/addToAddressAndFav';
import AddressSearch from '../../helperComponents/addessSearch/addressSearch';
import ParcelHandover from '../../carriageForward/parcelHandover/parcelHandover';
import { getServicepointWeight } from '../../../utils/utilsFunctions';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';

let dimsValidation = {};

const CollectFrom = (props) => {
  const {
    formField,
    formField: {
      fromAddressType,
      fromSearchFor,
      fromBusinessName,
      fromCustomerNo,
      fromPostCode,
      fromContactName,
      fromAddressLine1,
      fromAddressLine2,
      fromAddressLine3,
      fromTown,
      fromCounty,
      fromTelephoneNumber,
      fromEmailAddress,
      fromCustomerReference,
      collectionInstructions,
      descriptionOfGoods,
      fromWeight,
      fromParcel,
      collectionDate,
    },
    setFieldValue,
    formikValues,
    errors,
    setValues,
    setFromAddressSearchObj,
  } = props;
  const loadedFrom = useSelector((state) => state.loadedFrom.loadedFrom);
  const [openAddressBookModal, setOpenAddressBookModal] = React.useState(false);
  const [customerNumber, setCustomerNumber] = React.useState('');
  const [customerData, setCustomerData] = React.useState([]);
  const [postalSearchData, setPostalSearchData] = React.useState([]);
  const [isItemDisabled, setIsItemDisabled] = React.useState(false);
  const [workingDays, setWorkingDays] = React.useState([]);
  const [isDisPatchDateVisible, setIsDispatchDateVisible] = React.useState(true);
  let addressTypeData = [];
  let leftOverData = [];

  useEffect(() => {
    if (formikValues.handOverMethod === 'servicePoint') {
      setIsDispatchDateVisible(false);
    } else if (formikValues.handOverMethod === 'driverCollection') {
      setIsDispatchDateVisible(true);
    }
  }, [formikValues.handOverMethod])

  useEffect(async () => {
    let holidayRegion = '';
    formikValues?.accountNumber?.sender?.postalCode &&
      (await axiosConfig
        .get(`${ServiceEndPoints.getPostcodeDistrict}?postcode=${formikValues.accountNumber.sender.postalCode}`)
        .then((res) => {
          holidayRegion = res?.data?.ukHolidayRegion;
        })
        .catch((err) => console.log(err)));
    formikValues.accountNumber?.configuration &&
      GET(`${ServiceEndPoints.getPublicHolidays}?countryCode=gb`)
        .then((res) => {
          const publicHolidays = holidayRegion
            ? res.data?.publicHolidays.filter((holiday) => holiday.region === holidayRegion)
            : res.data?.publicHolidays;
          getWorkingDays(publicHolidays, setWorkingDays);
        })
        .catch((err) => {
          console.log(err);
        });
  }, [formikValues.accountNumber]);

  useEffect(() => {
    if (
      workingDays.length > 0 &&
      (!formikValues.collectionDate ||
        (formikValues.collectionDate &&
          new Date(formikValues.collectionDate).getTime() < new Date(workingDays[0]).getTime()))
    ) {
      setFieldValue('collectionDate', workingDays[0]);
    }
  }, [formikValues.collectionDate, setFieldValue, workingDays]);

  useEffect(() => {
    if (loadedFrom === '') {
      setFieldValue('collectionDate', workingDays.length > 0 ? workingDays[0] : '');
    }
  }, [workingDays]);

  useEffect(() => {
    if (formikValues.handOverMethod === 'servicePoint') {
      setFieldValue('fromParcel', 1);
      setIsItemDisabled(true)
    } else {
      setFieldValue('fromParcel', 1);
      setIsItemDisabled(false)
    }
  }, [formikValues.handOverMethod]);

  useEffect(() => {
    setFieldValue('fromSearchBy', SearchByOptions[0]);
  }, [setFieldValue]);

  React.useEffect(() => {
    setFieldValue('fromSearchFor', '');
  }, [props.formikValues.fromSearchBy]);

  const getCustomerAddressBook = (event) => {
    event.preventDefault();
    const url = `${ServiceEndPoints.getAddressBook}?organization=${getCustomerAccountID()}&${formikValues.fromSearchBy.value
      }=${formikValues.fromSearchFor}&countryCode=GB`;
    axiosConfig
      .get(url)
      .then((res) => {
        if (
          formikValues.fromSearchBy.value === 'customernumber' &&
          ((res.data || {}).addressBooks || []).length === 1
        ) {
          rowClicked(res.data.addressBooks[0]);
        } else {
          setOpenAddressBookModal(true);
          setCustomerData(res.data.addressBooks);
        }
      })
      .catch((err) => {
        setOpenAddressBookModal(true);
        setCustomerData([]);
        console.log(err);
      });
  };
  const rowClicked = (val) => {
    const address = val.deliveryAddress;
    setFromAddressSearchObj(val);
    setAddressToForm(address, true, val);
    setCustomerNumber(address.customerNumber);
    setOpenAddressBookModal(false);
  };

  const setAddressToForm = (address, isAddressBook = true, dimsDetails = null) => {
    let parcelDetails = {};
    const addressObj = {
      fromAddressType: isAddressBook
        ? address.recipientType === 1
          ? 'business'
          : 'residential'
        : address?.type === 'Commercial'
          ? 'business'
          : 'residential',
      fromTown: isAddressBook ? address?.town : address.city,
      fromAddressLine1: address?.line1,
      fromAddressLine2: address?.line2,
      fromAddressLine3: address?.line3,
      fromPostCode: isAddressBook ? address?.postCode?.toUpperCase() : address?.postalCode?.toUpperCase(),
      fromBusinessName: isAddressBook ? address?.businessName : address?.company,
      fromCustomerNo: address?.customerNumber,
      fromEmailAddress: address?.email,
      fromContactName: address?.recipientName,
      fromTelephoneNumber: address?.telephone,
      fromCounty: isAddressBook ? address?.county : address?.provinceName,
      fromCustomerReference: dimsDetails?.customerReference ?? '',
      collectionInstructions: dimsDetails?.collectionInstructions,
      descriptionOfGoods: dimsDetails?.descriptionOfGoodsToBeCollected,
    };
    //Set weight & items value
    if (dimsDetails) {
      if (formikValues.handOverMethod === 'servicePoint') {
        parcelDetails = {
          fromWeight: dimsDetails.parcelDetails?.weight,
        };
      } else {
        parcelDetails = {
          fromParcel: dimsDetails.parcelDetails?.numberOfItems,
          fromWeight: dimsDetails.parcelDetails?.weight,
        };
      }
    }
    removeUndefinedProperties(addressObj);
    setValues({ ...formikValues, ...addressObj, ...parcelDetails });
  };
  const handleClose = () => {
    setOpenAddressBookModal(false);
  };

  const isResidential = () => {
    return formikValues.fromAddressType === 'residential';
  };

  const setValuesFromPostalSearch = (val) => {
    setAddressToForm(val, false);
  };
  const customerNumberBlurred = (eve) => {
    setCustomerNumber(eve.target.value);
  };

  useEffect(() => {
    setFieldValue('sendLabelEmailAddress', formikValues.fromEmailAddress);
  }, [formikValues.fromEmailAddress, setFieldValue]);

  useEffect(() => {
    if (formikValues.fromAddressType === 'residential') {
      setFieldValue('fromBusinessName', '');
    }
  }, [formikValues.fromAddressType]);
  const setAddressValues = (addressObj) => {
    const formObj = {
      fromTown: addressObj.city,
      fromAddressLine1: addressObj.line1,
      fromAddressLine2: addressObj.line2,
      fromAddressLine3: addressObj.line3,
      fromPostCode: addressObj.postalCode,
      fromCounty: addressObj.provinceName,
      fromBusinessName: addressObj.company,
    };
    // removeUndefinedProperties(formObj);
    setValues({ ...formikValues, ...formObj });
  };

  const handleAddressChangeByPostCode = (addressType) => {
    if (addressType) {
      if (addressType === 'Commercial') {
        setFieldValue('fromAddressType', DeliveryTypeOptions[0].value);
      } else {
        setFieldValue('fromAddressType', DeliveryTypeOptions[1].value);
      }
    }
  };

  useEffect(() => {
    axiosConfig
      .get(`${ServiceEndPoints.productCountryFeature}?countrycode=GB`)
      .then((response) => {
        const nonPalletProducts = response.data.filter((val) => val.productCode !== 97 && val.productCode !== 98);

        let allMaxParcelsList = nonPalletProducts.map((x) => x.maximumParcels);
        let allMaxWeightList = nonPalletProducts.map((x) => x.maximumWeight);


        dimsValidation = {
          ...dimsValidation,
          maxParcels: Math.max(...allMaxParcelsList),
          maxWeight: Math.max(...allMaxWeightList),

        };

        fromParcel.validation.validations = fromParcel.validation.validations.filter(
          (x) => x.type !== 'max' && x.type !== 'min',
        );
        fromParcel.validation.validations.push({
          type: 'max',
          params: [dimsValidation.maxParcels, `Must be a value between 1 to ${dimsValidation.maxParcels}`],
        });
        fromParcel.validation.validations.push({
          type: 'min',
          params: [1, `Must be a value between 1 to ${dimsValidation.maxParcels}`],
        })
        fromParcel.props.inputProps.max = dimsValidation.maxParcels;

        fromWeight.validation.validations = fromWeight.validation.validations.filter(
          (x) => x.type !== 'max' && x.type !== 'min',
        );
        fromWeight.validation.validations.push({
          type: 'max',
          params: [dimsValidation.maxWeight, 'Weight is outside permitted range'],
        });
        fromWeight.validation.validations.push({
          type: 'min',
          params: [1, `Weight is outside permitted range`],
        });
        fromWeight.validation.validations.push({
          type: 'required',
          params: ['Weight field is required'],
        })
        fromWeight.props.inputProps.max = dimsValidation.maxWeight;
        props.updateValidationSchema?.();

        // Set the service point details using the weight calculated from the response data in store
        props.setServicePointDetails(getServicepointWeight(response.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (props.updateValidationSchema) {
      if (!formikValues?.accountNumber?.configuration?.consigneeEmailOptional) {
        !(fromEmailAddress.validation.validations.find((x) => x.type === 'required')) && fromEmailAddress.validation.validations.push({
          type: 'required',
          params: ['Email address is required'],
        });
        fromEmailAddress.props.isRequired = true;
      }
      else {
        fromEmailAddress.validation.validations = fromEmailAddress.validation.validations.filter((x) => x.type !== 'required');
        fromEmailAddress.props.isRequired = false;
      }
      props.updateValidationSchema();
    }
  }, [formikValues.accountNumber]);

  return (
    <Grid className="wd-100 collectFrom">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <ParcelHandover
            formField={formField}
            formikValues={formikValues}
            setFieldValue={setFieldValue}
            errors={errors}
          />
        </Grid>

        {(isDisPatchDateVisible) ?
          <Grid item xs={12} sm={6} md={12} lg={12} spacing={2} style={{ paddingTop: '10px' }}>
            <FormRenderer
              {...collectionDate.props}
              workingDays={workingDays}
              minDate={'nextDay'}
              isWorkingDays={true}
              fullWidth
            />
          </Grid> : null
        }
        {/* <ParcelHandover/> */}

        <Grid item>
          <FormRenderer
            {...fromSearchFor.props}
            selectProps={fromSearchFor.selectProps.props}
            textProps={fromSearchFor.textProps.props}
            data={SearchByOptions}
            onSearchClick={getCustomerAddressBook}
            isIconDisabled={formikValues.fromSearchFor?.trim() === ''}
            fullWidth
          />
          <CustomDialog
            open={openAddressBookModal}
            onClose={handleClose}
            title={customerData.length < 1 ? 'Customer not found' : 'Customer’s address search'}
          >
            <CustomAddressTable rows={customerData} rowClicked={rowClicked} />
          </CustomDialog>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormRenderer {...fromParcel.props} isDisabled={isItemDisabled} fullWidth />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormRenderer {...fromWeight.props} fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormRenderer {...fromAddressType.props} data={DeliveryTypeOptions} />
        </Grid>
        <Grid item>
          <FormRenderer {...fromCustomerNo.props} onBlur={(event) => customerNumberBlurred(event)} fullWidth />
        </Grid>
        <Grid item className="delivery-Address">
          <AddressSearch
            setAddressValues={setAddressValues}
            addressType={formikValues.fromAddressType}
            onSelectAddress={handleAddressChangeByPostCode}
            destinationCountrycode2Digit="GB"
            isDomestic={true}
          />
        </Grid>
        <Grid item>
          <FormRenderer
            {...fromPostCode.props}
            fullWidth
          />
        </Grid>
        <Grid item>
          <FormRenderer {...fromContactName.props} fullWidth />
        </Grid>
        {isResidential() ? null : (
          <Grid item>
            <FormRenderer {...fromBusinessName.props} fullWidth />
          </Grid>
        )}
        <Grid item>
          <FormRenderer {...fromAddressLine1.props} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer {...fromAddressLine2.props} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer {...fromAddressLine3.props} fullWidth />
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="space-evenly" spacing={2}>
            <Grid item xs={6}>
              <FormRenderer {...fromTown.props} fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FormRenderer {...fromCounty.props} fullWidth />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormRenderer {...fromTelephoneNumber.props} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer {...fromEmailAddress.props} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer {...fromCustomerReference.props} fullWidth />
        </Grid>
        <Grid item className="heading">
          Collection details
        </Grid>

        <Grid item>
          <FormRenderer {...collectionInstructions.props} fullWidth />
        </Grid>
        <Grid item>
          <FormRenderer {...descriptionOfGoods.props} fullWidth />
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item>
          <AddToAddressAndFav
            formField={formField}
            formikValues={formikValues}
            customerNumber={customerNumber}
            setFieldValue={setFieldValue}
            addToAddressBookControl="fromAddToaddressBook"
            addToFavControlName="fromAddToFavourites"
            updateAddressBookControl="updateFromAddToaddressBook"
            customerNumberControl="fromCustomerNo"
            addressSearchObj={setFromAddressSearchObj}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

CollectFrom.propTypes = {
  formField: PropTypes.object,
  fromAddressType: PropTypes.object,
  fromSearchFor: PropTypes.object,
  fromBusinessName: PropTypes.object,
  fromCustomerNo: PropTypes.object,
  fromPostCode: PropTypes.object,
  fromContactName: PropTypes.object,
  fromAddressLine1: PropTypes.object,
  fromAddressLine2: PropTypes.object,
  fromAddressLine3: PropTypes.object,
  fromTown: PropTypes.object,
  fromCounty: PropTypes.object,
  fromTelephoneNumber: PropTypes.object,
  fromEmailAddress: PropTypes.object,
  fromCustomerReference: PropTypes.object,
  collectionInstructions: PropTypes.object,
  descriptionOfGoods: PropTypes.object,
  setFieldValue: PropTypes.func,
  formikValues: PropTypes.object,
  errors: PropTypes.object,
};

const mapStateToProps = () => {
};

const mapDispatchToProps = (dispatch) => {
  return {
    setServicePointDetails: (data) => {
      dispatch(actions.setServicePointWeight(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectFrom);
